<template>
  <v-card class="pa-6 rounded-lg" outlined>
    <div class="text-body-1">
      <span >
        {{ analytic.title }}
      </span>
    </div>

    <div class="text-h5 secondary--text">
      <span >
        {{ analytic.value }}
      </span>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    analytic: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
