<template>
  <v-card class="pa-6 rounded-lg" outlined>
    <div class="d-flex align-center mb-2">
      <div class="d-flex align-center">
        <div class="text-h6">
          <span > Faturas da propriedade </span>
        </div>
      </div>
    </div>

    <v-divider class="my-4" />

    <v-data-table
      class="secondary--text transparent custom-table"
      :headers="headers"
      :items="installments"
      hide-default-footer
      disable-pagination
      disable-sort
    >
      <template v-slot:[`item.value`]="{ item }">
        <span class="secondary--text font-weight-bold">
          <span > {{ currencyMask(item.value) }} BRL </span>
        </span>
      </template>

      <template v-slot:[`item.contract`]="{ item }">
        <span >
          {{ item.nomeContrato }}
        </span>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <span >
          {{ formatDate(item.date) }}
        </span>
      </template>

      <template v-slot:[`item.endDate`]="{ item }">
        <span >
          {{ formatDate(item.endDate) }}
        </span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip class="rounded-lg" outlined>
          <span >
            {{ handleStatus(item.status) }}
          </span>
        </v-chip>
      </template>

      <template v-slot:[`item.payment`]="{ item }">
        <v-icon v-if="item.status === '1'">mdi-check</v-icon>

        <v-icon v-else-if="item.status === '2'">mdi-clock-outline</v-icon>

        <v-btn v-else icon @click="sendPayment(item)">
          <v-icon>mdi-receipt-send-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <SendInstallment ref="sendInstallment" @success="$emit('refresh')" />
  </v-card>
</template>

<script>
import { currencyMask, formatDate } from "@/utils";
import SendInstallment from "@/components/deposit/SendInstallment.vue";

export default {
  data() {
    return {
      filters: [
        {
          label: "Entradas",
          value: "deposits",
        },
        {
          label: "Saídas",
          value: "withdrawals",
        },
      ],
      filter: "deposits",
      headers: [
        { text: "Valor", value: "value" },
        { text: "Data", value: "date" },
        { text: "Data de vencimento", value: "endDate" },
        { text: "Status", value: "status" },
        { text: "Pagamento", value: "payment", align: "center" },
      ],
    };
  },

  props: {
    installments: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    SendInstallment,
  },

  methods: {
    handleStatus(value) {
      switch (String(value)) {
        case "0":
          return "Pendente";
        case "1":
          return "Aprovado";
        case "-1":
          return "Recusado";
        case "2":
          return "Em análise";
        default: {
          return "";
        }
      }
    },

    sendPayment(data) {
      const payload = {
        installmentID: data.id,
        contractID: data.contractID,
        value: data.value,
      };

      this.$refs.sendInstallment.open(payload);
    },

    currencyMask,

    formatDate,
  },
};
</script>

<style lang="scss" scoped>
::v-deep
  .custom-table.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td {
  border-bottom: none !important;
}

::v-deep .custom-table.v-data-table > .v-data-table__wrapper > table > thead {
  background-color: #fafafa;
}

::v-deep
  .custom-table.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  border-bottom: none !important;
  height: 32px !important;
}

.chip {
  background-color: #29aa4d20 !important;
}
</style>
