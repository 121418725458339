<template>
  <loader-view v-if="loading.content" />

  <v-container v-else class="py-12">
    <v-row>
      <v-col v-for="(item, i) in analytics" :key="i">
        <AnalyticCard :analytic="item" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <IncomeChart :chartData="chartData" @submit="getChart($event)" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" lg="7" xl="8">
        <RecentTransactions class="mb-6" :transactions="income" />

        <PendingTimeshare :installments="installments" @refresh="getData()" />
      </v-col>

      <v-col cols="12" md="6" lg="5" xl="4">
        <PropertyDetailsCard
          v-if="property"
          class="mb-6"
          :property="property"
          @withdraw="handleWithdraw()"
        />

        <v-card class="px-6 py-4" outlined>
          <div class="mb-2 text-h6 font-weight-regular overtext--text">
            <span >
              Contrato da sua UCS nº {{ $route.params.id }}
            </span>
          </div>

          <router-link
            :to="`/my-properties/${$route.params.id}/see-contract`"
            class="text-decoration-none"
            target="_blank"
          >
            <v-btn
              color="primary2"
              class="rounded-lg text-none overtext--text"
              block
            >
              Visualizar contrato
            </v-btn>
          </router-link>
        </v-card>
      </v-col>
    </v-row>

    <Withdraw
      ref="withdraw"
      :available="availableBalance"
      @submit="withdrawSubmit($event)"
    />

    <loader-hover v-if="loading.withdraw" />
  </v-container>
</template>

<script>
import { request } from "@/services";
import { currencyMask, parseNumber } from "@/utils";
import AnalyticCard from "@/components/myProperties/AnalyticCard.vue";
import RecentTransactions from "@/components/myProperties/RecentTransactions.vue";
import PropertyDetailsCard from "@/components/myProperties/PropertyDetailsCard.vue";
import IncomeChart from "@/components/earnings/IncomeChart.vue";
import Withdraw from "@/components/withdraw/Withdraw.vue";
import PendingTimeshare from "@/components/myProperties/PendingTimeshare.vue";

export default {
  data() {
    return {
      loading: {
        content: true,
        withdraw: false,
      },
      analytics: [
        {
          title: "Total ganho",
          value: 0,
        },
        {
          title: "Total sacado",
          value: 0,
        },
        {
          title: "Depositado",
          value: 0,
        },
        {
          title: "Disponivel",
          value: 0,
        },
      ],
      availableBalance: 0,
      chartData: {
        yield: [],
        withdraw: [],
      },
      income: [],
      property: null,
      installments: [],
    };
  },

  beforeMount() {
    this.getData();
  },

  components: {
    AnalyticCard,
    IncomeChart,
    RecentTransactions,
    PropertyDetailsCard,
    Withdraw,
    PendingTimeshare,
  },

  methods: {
    async getData() {
      await this.getDetails();
      await this.getIncome();
      await this.getChart();

      this.loading.content = false;
    },

    async getDetails() {
      try {
        const payload = {
          method: "getUserContratoDetail",
          contratoID: this.$route.params.id,
        };

        await request(payload).then((res) => {
          const profit =
            Number(res.contratoDetail.saldoAtual) -
            Number(res.contratoDetail.valorDeposito);
          const available =
            res.contratoDetail.vencido === "1"
              ? res.contratoDetail.saldoAtual
              : profit;

          this.analytics[0].value = currencyMask(profit) + " BRL";
          this.analytics[1].value =
            currencyMask(res.contratoDetail.totalSacado) + " BRL";
          this.analytics[2].value =
            currencyMask(res.contratoDetail.valorDeposito) + " BRL";
          this.analytics[3].value = currencyMask(available) + " BRL";
          this.availableBalance = available;

          this.property = {
            image: res.contratoDetail.iconeContrato,
            name: res.contratoDetail.nomeContrato,
            propertyDate: res.contratoDetail.dataContrato,
            rentDate: res.contratoDetail.dataComecaRentabilizar,
            withdrawDate: res.contratoDetail.dataLiberaSaque,
            balance: Number(res.contratoDetail.saldoAtual),
            availableBalance: res.contratoDetail.available,
            expired: res.contratoDetail.vencido === "1",
            contractURL:
              this.$store.getters.apiURL + res.contratoDetail.linkPDF,
          };

          this.installments = res.contratoDetail.parcelasInfo.map((el) => ({
            id: el.id,
            contractID: el.contratoID,
            value: el.valor,
            status: el.status,
            order: el.ordemParcela,
            endDate: el.dataVencimento,
            date: el.data,
          }));
        });
      } catch (err) {
        console.log(err);
      }
    },

    async getIncome() {
      try {
        const payload = {
          method: "getUserContratoRendimentos",
          userContratoID: this.$route.params.id,
        };

        await request(payload).then((res) => {
          this.income = res.rendimentos;
        });
      } catch (err) {
        console.log(err);
      }
    },

    async getChart(filter = 6) {
      try {
        const payload = {
          method: "getUserContratoGrafico",
          userContratoID: this.$route.params.id,
          qntMeses: filter,
        };

        await request(payload).then((res) => {
          const chartData = res.graficoData.reverse();

          this.chartData.yield = chartData.map((e) => e.Rendimentos);
          this.chartData.withdraw = chartData.map((e) => e.Saques);
        });
      } catch (err) {
        console.log(err);
      }
    },

    async withdrawSubmit(event) {
      try {
        this.loading.withdraw = true;

        const payload = {
          method: "solicitaSaque",
          userContratoID: this.$route.params.id,
          valor: parseNumber(event.value),
          bankKeyID: event.pixKey,
          tipo: 1,
        };

        request(payload).then(() => {
          this.displayAlert(res.message);
        });
      } catch (err) {
        this.displayAlert(err.message, 1);
      } finally {
        this.loading.withdraw = false;
      }
    },

    handleWithdraw() {
      this.$refs.withdraw.open();
    },
  },
};
</script>

<style></style>
