<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card class="rounded-lg fill-height pa-6" flat>
      <div class="d-flex mb-4">
        <div class="text-h6 overtext--text">
          <span >
            Realize o pagamento e envie seu comprovante
          </span>
        </div>

        <v-spacer />

        <v-icon @click="close()">mdi-close</v-icon>
      </div>

      <v-form v-model="valid" ref="form">
        <div class="mb-6 text-h6 font-weight-regular">
          Valor a ser transferido:
          <span class="font-weight-bold text-decoration-underline">
            BRL
            {{ value }}
          </span>
        </div>

        <custom-select
          v-model="form.transferAccount"
          ref="transferAccount"
          label="Banco"
          placeholder="Selecione o banco"
          :items="transferAccounts"
          itemValue="id"
          itemText="name"
          outlined
        />

        <section
          v-if="
            _currentTransferAccount &&
            _currentTransferAccount.name.toUpperCase() === 'PIX'
          "
          class="mb-4"
        >
          <v-card class="pa-6" outlined>
            <div v-if="openPix">
              <div class="text-center text-body-1 mb-1">
                Copie o código ou transfira R$
                {{ value }} diretamente para esse QR Code.
              </div>

              <div class="text-center text-caption mb-2">
                QR code válido por somente {{ qrCodeTime }} minutos
              </div>

              <v-row justify="center">
                <v-col cols="12" md="6">
                  <v-img class="mb-4" :src="openPix.charge.qrCodeImage" />

                  <div class="d-flex align-center">
                    <input
                      v-model="openPix.brCode"
                      id="code"
                      class="font-weight-regular text-caption pa-3"
                      readonly
                      @click="copyCode()"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>

            <loader-content v-else />
          </v-card>
        </section>

        <section v-else>
          <BankCard
            v-if="_currentTransferAccount"
            class="mb-6"
            :text="_currentTransferAccount.description"
          />
        </section>

        <card-file-input
          v-model="form.transferReceipt"
          class="mb-4"
          title="Anexe seu comprovante de transferencia"
        />
      </v-form>

      <v-btn
        color="primary2"
        class="rounded-xl secondary--text"
        large
        block
        @click="submit()"
      >
        Enviar pagamento
      </v-btn>
    </v-card>

    <loader-hover v-if="loading" />
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import BankCard from "@/components/investment/BankCard.vue";
import { displayAlert, currencyMask } from "@/utils";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      interval: null,
      valid: false,
      currencies: [],
      installmentID: null,
      contractID: null,
      value: "0,00",
      transferAccounts: [],
      form: {
        transferAccount: "",
        transferReceipt: null,
      },
      openPix: null,
      qrCodeTime: "",
    };
  },

  components: {
    BankCard,
  },

  beforeMount() {
    this.getCurrencies();
  },

  watch: {
    ["_currentTransferAccount.name"]: {
      handler(val) {
        if (val && !this.openPix && val.toUpperCase() === "PIX") this.getPix();
      },
      deep: true,
    },

    dialog() {
      if (!this.dialog) this.openPix = null;
    },
  },

  computed: {
    _currentTransferAccount() {
      if (!this.form.transferAccount) return;

      return this.transferAccounts.find(
        (el) => el.id === this.form.transferAccount
      );
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "newParcelaPagamento",
          parcelaID: this.installmentID,
          contratoID: this.contractID,
          comprovante: this.form.transferReceipt,
        };

        await request(payload).then(() => {
          this.displayAlert("Pagamento enviado");
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err, 1);
      } finally {
        this.loading = false;
      }
    },

    async getPix() {
      try {
        const payload = {
          method: "getQrCodePix",
          valor: parseNumber(this.value),
        };

        await request(payload).then((res) => {
          this.openPix = res.apiData;
          this.getRemaining(this.openPix.charge.expiresDate);
        });
      } catch (error) {
        console.log(error);
      }
    },

    // get available currencies
    async getCurrencies() {
      try {
        const payload = {
          method: "getMoedas",
        };

        await request(payload).then((res) => {
          this.currencies = res.moedas;
          this.getMethods();
        });
      } catch (err) {
        console.log(err);
      }
    },

    // return methods of payment for currency
    async getMethods() {
      try {
        const payload = {
          method: "getRedeMoeda",
          moedaID: this.currencies[0].id,
        };

        await request(payload).then((res) => {
          this.transferAccounts = res.redes
            .filter((e) => e.avaliable === "1")
            .map((e) => {
              return {
                id: e.id,
                name: e.nomeRede,
                description: e.infoRede,
              };
            });
        });
      } catch (err) {
        console.log(err);
      }
    },

    open(data) {
      this.dialog = true;
      this.value = currencyMask(data.value);
      this.installmentID = data.installmentID;
      this.contractID = data.contractID;

      this.form = {
        transferAccount: "",
        transferReceipt: null,
      };

      this.$refs.transferAccount.handleInput("");
    },

    close() {
      this.dialog = false;
    },

    copyCode() {
      navigator.clipboard.writeText(this.openPix.brCode);
    },

    getRemaining(finishDate) {
      const moment = require("moment");
      moment.locale("pt-BR");

      // Set the date we're counting down to
      finishDate = moment(finishDate).format("YYYY-MM-DD HH:mm:ss");
      var countDownDate = new Date(finishDate).getTime();

      // Update the count down every 1 second
      this.interval = setInterval(() => {
        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for minutes and seconds
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.qrCodeTime =
          String(minutes).padStart(2, "0") +
          ":" +
          String(seconds).padStart(2, "0");

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(this.interval);
          this.clearOpenPix();
        }
      }, 1000);
    },

    clearOpenPix() {
      this.openPix = null;
      this.qrCodeTime = "";

      if (!!this.form.transferAccount) {
        this.form.transferAccount = "";
        this.$refs.transferAccount.handleInput("");
      }
    },

    displayAlert,
  },
};
</script>

<style lang="scss" scoped>
input {
  text-align: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #bcbdbd;
}

input:focus {
  border: 1px solid #bcbdbd;
}

.bordered {
  border: 0.25rem solid #00cca6;
}
</style>
