<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card class="rounded-lg fill-height pa-6" flat>
      <div class="d-flex mb-4">
        <div class="text-h6 overtext--text">
          <span > Seus dados para recebimento </span>
        </div>

        <v-spacer />

        <v-icon @click="close()">mdi-close</v-icon>
      </div>

      <v-form v-model="valid" ref="form">
        <v-row>
          <v-col>
            <custom-select
              v-model="form.pixKey"
              :items="pixKeys"
              ref="pixKey"
              label="Sua chave pix"
              placeholder="Selecione a chave"
              outlined
            />
          </v-col>

          <v-col>
            <custom-money-input
              v-model="form.value"
              id="value"
              ref="value"
              label="Valor"
              :customRules="valueRules"
              outlined
            />
          </v-col>
        </v-row>
      </v-form>

      <v-divider class="mb-4" />

      <v-row align="center">
        <v-col cols="12" sm="6">
          <div class="font-weight-light subtext--text">
            <span > Disponível para saque </span>
          </div>

          <div class="text-h5 secondary--text">
            <span > {{ currencyMask(available) }} BRL </span>
          </div>
        </v-col>

        <v-col cols="12" sm="6" class="d-flex justify-end">
          <v-btn
            class="rounded-xl secondary--text"
            color="primary2"
            :disabled="!valid"
            @click="submit()"
          >
            Solicitar saque
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { currencyMask, parseNumber } from "@/utils";

export default {
  data() {
    return {
      dialog: false,
      valid: false,
      pixKeys: [],
      form: {
        pixKey: "",
        value: "",
      },
    };
  },

  props: {
    available: {
      type: Number,
      default: 0,
    },
  },

  beforeMount() {
    this.startData();
  },

  computed: {
    ...mapState(["user"]),

    valueRules() {
      return [
        (v) => parseNumber(v) >= 10 || `Valor mínimo de 10,00 BRL`,
        (v) =>
          parseNumber(v) <= this.available ||
          `Valor máximo de ${currencyMask(this.available)} BRL`,
      ];
    },
  },

  methods: {
    submit() {
      this.$emit("submit", this.form);
      this.close();
    },

    startData() {
      this.pixKeys = this.user.chavesBancarias.map((e) => {
        return {
          label: e.chave,
          value: e.id,
        };
      });
    },

    startForm() {
      this.$refs.pixKey.handleInput("");
      this.$refs.value.handleInput("");
      this.$refs.form.resetValidation();
    },

    open() {
      this.dialog = true;

      setTimeout(() => {
        this.startForm();
      });
    },

    close() {
      this.dialog = false;
    },

    currencyMask,
  },
};
</script>

<style lang="scss" scoped></style>
