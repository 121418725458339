<template>
  <v-card class="rounded-lg pa-6" outlined>
    <div class="d-flex align-center">
      <div class="text-h6">
        <span >Ultimas movimentações</span>
      </div>
    </div>

    <v-divider class="my-4" />

    <v-data-table
      class="transparent custom-table"
      :headers="headers"
      :items="transactions"
      hide-default-footer
      disable-pagination
      disable-sort
    >
      <template v-slot:[`item.description`]="{ item }">
        <span >
          {{ item.descricao }}
        </span>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <span >
          {{ formatDate(item.data) }}
        </span>
      </template>

      <template v-slot:[`item.yield`]="{ item }">
        <span >
          {{ item["valor%"] }}
        </span>
      </template>

      <template v-slot:[`item.value`]="{ item }">
        <v-chip class="chip" label>
          <span class="primary--text font-weight-bold">
            <span >R$ {{ currencyMask(item.valor) }} </span>
          </span>
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { currencyMask, formatDate } from "@/utils";

export default {
  data() {
    return {
      headers: [
        { text: "Descrição", value: "description" },
        { text: "Data", value: "date" },
        { text: "Rendimento", value: "yield" },
        { text: "Valor", value: "value" },
      ],
    };
  },

  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    currencyMask,

    formatDate,
  },
};
</script>

<style lang="scss" scoped>
::v-deep
  .custom-table.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td {
  border-bottom: none !important;
}

::v-deep
  .custom-table.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  border-bottom: none !important;
  height: 32px !important;
}

.chip {
  background-color: #29aa4d20 !important;
}
</style>
