<template>
  <v-card class="pa-6 rounded-lg" outlined>
    <div class="d-flex align-center">
      <v-avatar class="mr-4" size="48">
        <v-img :src="_image" />
      </v-avatar>

      <div class="d-flex flex-column">
        <div class="secondary--text text-body-1 mb-n1">
          <span >
            {{ property.name }}
          </span>
        </div>
        <div class="subtext--text text-body-2">
          <span > UCS </span>
        </div>
      </div>
    </div>

    <v-divider class="my-4" />

    <div v-for="(item, i) in _list" class="d-flex align-center mb-6" :key="i">
      <v-icon class="mr-2" color="green">{{ item.icon }}</v-icon>

      <div class="subtext--text mr-2">
        <span >
          {{ item.label }}
        </span>
      </div>

      <v-spacer />

      <div class="seconadry--text text-body-1">
        <span >
          {{ item.value }}
        </span>
      </div>
    </div>

    <v-btn
      class="rounded-lg overtext--text text-none"
      color="primary2"
      block
      @click="withdrawSubmit()"
    >
      Solicitar saque
    </v-btn>
  </v-card>
</template>

<script>
import { currencyMask } from "@/utils";

export default {
  props: {
    property: {
      type: Object,
      default: {},
    },
  },

  computed: {
    _image() {
      return this.$store.getters.apiURL + this.property.image;
    },

    _list() {
      return [
        {
          icon: "mdi-calendar-outline",
          label: "Data da aplicação",
          value: this.property.propertyDate,
        },
        {
          icon: "mdi-calendar-star-outline",
          label: "Data de rentabilidade",
          value: this.property.rentDate,
        },
        {
          icon: "mdi-calendar-clock-outline",
          label: "Data de saque",
          value: this.property.withdrawDate,
        },
        {
          icon: "mdi-home-variant-outline",
          label: "Valor total da UCS",
          value: currencyMask(this.property.balance) + " BRL",
        },
        {
          icon: "mdi-currency-usd",
          label: "Disponível para saque",
          value: currencyMask(this.property.availableBalance) + " BRL",
        },
      ];
    },
  },

  methods: {
    withdrawSubmit() {
      this.$emit("withdraw");
    },

    currencyMask,
  },
};
</script>

<style lang="scss" scoped></style>
